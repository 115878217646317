import React, { useEffect, useState, useCallback } from "react";
import { LoaderSVGWhite } from "../../svg/loadingWhite";

function ProfilStatusSync({ syncWP, syncGoogle, onReloadParent, employeeID }) {
  const [loading, setLoading] = useState(false);
  const [currentSyncWP, setCurrentSyncWP] = useState(
    syncWP !== null ? Boolean(syncWP) : null
  );
  const [currentSyncGoogle, setCurrentSyncGoogle] = useState(
    syncGoogle !== null ? Boolean(syncGoogle) : null
  );
  const [allSynced, setAllSynced] = useState(false);

  const myHeaders = new Headers({
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_JWT_KEY || "",
  });

  useEffect(() => {
    if (!process.env.REACT_APP_JWT_KEY) {
      console.error("JWT key is not defined");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSyncWP !== true || currentSyncGoogle !== true) {
        checkSyncState();
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSyncWP, currentSyncGoogle]);

  useEffect(() => {
    if (currentSyncWP === true && currentSyncGoogle === true) {
      setAllSynced(true);
    }
  }, [currentSyncWP, currentSyncGoogle]);

  const checkSyncState = useCallback(async () => {
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `https://data.bebook.becomers.fr/proxy/apis/get-state/${employeeID}`,
        requestOptions
      );
      const result = await response.json();

      if (response.ok) {
        setCurrentSyncWP(result.facebook_sync_state === 1);
        setCurrentSyncGoogle(result.google_sync_state === 1);
      } else {
        console.error("Erreur de requête:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  }, [employeeID, myHeaders]);

  const updateProfil = useCallback(async () => {
    setLoading(true);

    if (!process.env.REACT_APP_JWT_KEY) {
      console.error("JWT key is not defined");
      setLoading(false);
      return;
    }

    const requestData = { id: employeeID };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_SYNC_APIS,
        requestOptions
      );

      if (response.ok) {
        onReloadParent(false);
      } else {
        console.error("Erreur de requête:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      setLoading(false);
    }
  }, [employeeID, myHeaders, onReloadParent]);

  const statusWP = currentSyncWP ? "sync-yes" : "sync-no";
  const statusGoogle = currentSyncGoogle ? "sync-yes" : "sync-no";

  return (
    <div className="bg-gray p-4 mb-4 center sync">
      <h4>Synchronisé :</h4>
      <div>
        Workplace :{" "}
        <span className={`sync-status ${statusWP}`}>
          {currentSyncWP === null ? "en cours" : currentSyncWP ? "Oui" : "Non"}
        </span>
      </div>
      <div>
        Google :{" "}
        <span className={`sync-status ${statusGoogle}`}>
          {currentSyncGoogle === null
            ? "en cours"
            : currentSyncGoogle
            ? "Oui"
            : "Non"}
        </span>
      </div>

      {!allSynced &&
        (currentSyncGoogle === false || currentSyncWP === false) && (
          <button
            onClick={updateProfil}
            className="mt-2 sync-btn btn btn-primary"
            disabled={loading}
          >
            {loading ? <LoaderSVGWhite /> : "Synchroniser maintenant"}
          </button>
        )}
    </div>
  );
}

export default ProfilStatusSync;
