import { useContext, useState } from "react";

import { UserContext } from "./UserContext";
import { NavLink } from "react-router-dom";

import { NavDropdown } from "react-bootstrap";
import { auth } from "../services/firebase";
import { SupportSVG } from "./app/svg/support";
import { SignoutSVG } from "./app/svg/signout";
import { HomeSVG } from "./app/svg/home";
import { PersonsSVG } from "./app/svg/persons";
import { LockSVG } from "./app/svg/lock";
import { InOutSVG } from "./app/svg/inout";

import { OrganigrammeSVG } from "./app/svg/organigramme";
import { LicenceSVG } from "./app/svg/licence";
import { FreecomersSVG } from "./app/svg/freecomers";
import { AuthorizedUserContext } from "./AuthorizedUserContext";

const Menu = (props) => {
  const [isOpen, setIsopen] = useState(false);

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  const autoriserAffichageAdmin = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 3);
  };

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const CloseSidebar = () => {
    setIsopen(false);
  };

  const roleNames = props.roles.map((role) => role.name);
  const roleNamesString = roleNames.join(", ");

  return (
    <UserContext.Consumer>
      {(data) => {
        const user = data.providerData[0];

        const firstNameInitial = user.displayName.charAt(0);
        const lastNameInitial = user.displayName.split(" ")[1].charAt(0);

        const concatenatedInitials = firstNameInitial + lastNameInitial;

        return (
          <>
            <div className="container-fluid p-0 ">
              <nav className="navbar navbar-expand-lg navbar-dark bg-white shadow-md">
                <div className="container-fluid p-2">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div className="btn " onClick={ToggleSidebar}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="21"
                        viewBox="0 0 25 21"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 19.95C0 19.6715 0.109747 19.4044 0.305097 19.2075C0.500448 19.0106 0.765399 18.9 1.04167 18.9H23.9583C24.2346 18.9 24.4996 19.0106 24.6949 19.2075C24.8903 19.4044 25 19.6715 25 19.95C25 20.2285 24.8903 20.4955 24.6949 20.6925C24.4996 20.8894 24.2346 21 23.9583 21H1.04167C0.765399 21 0.500448 20.8894 0.305097 20.6925C0.109747 20.4955 0 20.2285 0 19.95ZM0 13.65C0 13.3715 0.109747 13.1044 0.305097 12.9075C0.500448 12.7106 0.765399 12.6 1.04167 12.6H23.9583C24.2346 12.6 24.4996 12.7106 24.6949 12.9075C24.8903 13.1044 25 13.3715 25 13.65C25 13.9285 24.8903 14.1955 24.6949 14.3925C24.4996 14.5894 24.2346 14.7 23.9583 14.7H1.04167C0.765399 14.7 0.500448 14.5894 0.305097 14.3925C0.109747 14.1955 0 13.9285 0 13.65ZM0 7.35C0 7.07152 0.109747 6.80445 0.305097 6.60754C0.500448 6.41062 0.765399 6.3 1.04167 6.3H23.9583C24.2346 6.3 24.4996 6.41062 24.6949 6.60754C24.8903 6.80445 25 7.07152 25 7.35C25 7.62848 24.8903 7.89555 24.6949 8.09246C24.4996 8.28937 24.2346 8.4 23.9583 8.4H1.04167C0.765399 8.4 0.500448 8.28937 0.305097 8.09246C0.109747 7.89555 0 7.62848 0 7.35ZM0 1.05C0 0.771523 0.109747 0.504451 0.305097 0.307538C0.500448 0.110625 0.765399 0 1.04167 0H23.9583C24.2346 0 24.4996 0.110625 24.6949 0.307538C24.8903 0.504451 25 0.771523 25 1.05C25 1.32848 24.8903 1.59555 24.6949 1.79246C24.4996 1.98937 24.2346 2.1 23.9583 2.1H1.04167C0.765399 2.1 0.500448 1.98937 0.305097 1.79246C0.109747 1.59555 0 1.32848 0 1.05Z"
                          fill="black"
                        />
                      </svg>
                    </div>

                    <NavLink
                      className="logo-link "
                      to="/"
                      onClick={CloseSidebar}
                    >
                      BeBook
                    </NavLink>

                    {autoriserAffichageRH(rolesUserRolesUser) && (
                      <NavLink
                        className="btn btn-primary ml-5 btn-menu"
                        to="/collaborateurs/ajouter-collaborateur"
                        onClick={CloseSidebar}
                      >
                        Ajouter un.e employé.e
                      </NavLink>
                    )}
                  </div>
                  <div className="form-inline ml-auto"></div>

                  <NavDropdown
                    className="bebook-drop "
                    title={concatenatedInitials}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.ItemText
                      className="email-info"
                      style={{ width: "max-content" }}
                    >
                      {user.email}
                    </NavDropdown.ItemText>

                    <NavDropdown.ItemText>
                      <SupportSVG />
                      <span>Mes roles : {roleNamesString}</span>
                    </NavDropdown.ItemText>

                    <NavDropdown.Item href="mailto:support@becoming-group.com">
                      <SupportSVG /> <span>Support</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="signout"
                      onClick={() => auth.signOut()}
                    >
                      <SignoutSVG /> <span>Déconnexion</span>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </nav>

              <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
                <div className="sd-body">
                  <nav
                    onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <NavLink
                          onClick={CloseSidebar}
                          className="nav-link "
                          aria-current="page"
                          to="/"
                        >
                          <span>
                            <HomeSVG />
                          </span>{" "}
                          <span>Accueil</span>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/collaborateurs"
                          onClick={CloseSidebar}
                        >
                          <span>
                            <PersonsSVG />
                          </span>{" "}
                          <span>Collaborateurs</span>
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/archives"
                          onClick={CloseSidebar}
                        >
                          <span>
                            <PersonsSVG />
                          </span>{" "}
                          <span>Archivés</span>
                        </NavLink>
                      </li>

                      {autoriserAffichageAdmin(rolesUserRolesUser) && (
                        <li className="nav-item">
                          <NavLink
                            className="nav-link"
                            to="/licences"
                            onClick={CloseSidebar}
                          >
                            <span>
                              <LicenceSVG />
                            </span>
                            <span>Licences</span>
                          </NavLink>
                        </li>
                      )}

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/collaborateurs/entree-sortie"
                          onClick={CloseSidebar}
                        >
                          <span>
                            <InOutSVG />
                          </span>{" "}
                          <span>Entrée - Sortie collaborateurs</span>
                        </NavLink>
                      </li>

                      {autoriserAffichageAdmin(rolesUserRolesUser) && (
                        <>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/organigramme"
                              onClick={CloseSidebar}
                            >
                              <span>
                                <OrganigrammeSVG />
                              </span>{" "}
                              <span>Organigramme</span>
                            </NavLink>
                          </li>

                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/freecomers"
                              onClick={CloseSidebar}
                            >
                              <span>
                                <FreecomersSVG />
                              </span>
                              <span>Freecomers</span>
                            </NavLink>
                          </li>
                        </>
                      )}

                      {autoriserAffichageRH(rolesUserRolesUser) && (
                        <li className="nav-item">
                          <NavLink
                            className="nav-link"
                            to="/admin"
                            onClick={CloseSidebar}
                          >
                            <span>
                              <LockSVG />
                            </span>{" "}
                            <span>Admin</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
              <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
            </div>
          </>
        );
      }}
    </UserContext.Consumer>
  );
};

export default Menu;
